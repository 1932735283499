import React from "react"
import patisserieHero from '../images/patisserieHero.jpg';
import Layout from "../components/layout"
import Product from "../components/product/product"
import SEO from "../components/seo"
import bis from "../images/products/pastry/biscuitFruits.jpg";
import choc from "../images/products/pastry/chocotine.jpg";
import del from "../images/products/pastry/delicieux.jpg";
import ele from "../images/products/pastry/elegance.jpg";
import fore from "../images/products/pastry/foretNoire.jpg";
import fra from "../images/products/pastry/framboisine.jpg";
import lim from "../images/products/pastry/limencello.jpg";
import sai from "../images/products/pastry/saintHonore.jpg";
import sou from "../images/products/pastry/sousBois.jpg";
import ten from "../images/products/pastry/tentationExotique.jpg";
import tro from "../images/products/pastry/troisChocolat.jpg";

const products =[
  {
    name:"Biscuit aux fruits",
    description : "Génoise, crème pâtissière allégée et fruits frais de saison.",
    img : bis
  },
  {
    name:"Chocotine",
    description : "Mousse au chocolat 55 %, crémeux à la vanille, croustillant chocolat, biscuit chocolat.",
    img : choc
  },
  {
    name:"Limancello",
    description : "Mousse citron limonade, insert fraise, biscuit citron et sablé croquant.",
    img : lim
  },
  {
    name:"Délicieux",
    description : "Mousse au chocolat 55 % , mousse framboise et biscuit moelleux aux amandes.",
    img : del
  },
  {
    name:"Sous-bois",
    description : "Mousse mascarpone vanille, insert fruits rouges et biscuit cuillère.",
    img : sou
  },
  {
    name:"Elégance",
    description : "Mousse au chocolat 66 %, crémeux au chocolat 66 % et biscuit chocolat.",
    img : ele
  },
  {
    name:"Forêt-Noire",
    description : "Biscuit au chocolat, compotée de cerises, crème légère au kirsch.",
    img : fore
  },
  {
    name:"Framboisine",
    description : "Mousse framboise, bavaroise vanille et biscuit moelleux amande.",
    img : fra
  },
  {
    name:"Saint-Honoré",
    description : "Crème chiboust à la vanille, pâte brisée et pâte à choux.",
    img : sai
  },
  {
    name:"Tentation exotique",
    description : "Mousse crème cheese vanille tonka, insert exotique et dacquoise coco.",
    img : ten
  },
  {
    name:"Trois chocolats",
    description : "Mousse au chocolat noir, lait et blanc, génoise.",
    img : tro
  }
]

const IndexPage = () => (
  <Layout>
    <SEO title="Pâtisserie" />
    <div className="products">
      <section>
        <div className="fluid-container">
          <div className="row">
            <img src={patisserieHero} alt="Pâtisserie" style={{width:"100%"}}/>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <h1 className="col-md-12 times" style={{textAlign:"center",margin:"62px 0px"}}>Nos pâtisseries</h1>
          </div>
          <div className="row">
            <div className="col-md-12" style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}>
            {
              products.map((product)=><Product name={product.name} description={product.description} img={product.img} />)
            }
            </div>
          </div>
        </div>
      </section>

    </div>
  </Layout>
)

export default IndexPage
